import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  CircularProgress,
  FormHelperText,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import RemoveIcon from '@mui/icons-material/Remove';

const FullScreenContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start', // Align items to the top
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3),
}));

const SettingsPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  maxWidth: '1200px',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(4), // Space at the bottom for logout button
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(1.5),
  fontSize: '1rem',
}));

const Header = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  padding: theme.spacing(0.5, 1.5),
  fontSize: '0.875rem',
  alignSelf: 'center',
  '&:hover': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '2px', // Increase border width
    },
    '&:hover fieldset': {
      borderWidth: '2px',
    },
    '&.Mui-focused fieldset': {
      borderWidth: '2px',
      // Prevent input from expanding on focus
      transform: 'none',
    },
  },
}));

const SettingsPage = () => {
  const auth = getAuth();
  const db = getFirestore();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reportFrequency, setReportFrequency] = useState('');
  const [domains, setDomains] = useState(['']);
  const [subscriptionLevel, setSubscriptionLevel] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserData(data);
            setReportFrequency(data.reportFrequency || 'monthly');
            setDomains(data.domains || ['']);
            setSubscriptionLevel(data.subscriptionType || 'essential');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserData();
  }, [auth, db]);

  const handleSaveSettings = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        await setDoc(
          doc(db, 'users', user.uid),
          {
            reportFrequency,
            domains,
          },
          { merge: true }
        );
        alert('Settings updated successfully');
      } catch (error) {
        console.error('Error saving settings:', error);
        alert('Failed to update settings');
      }
    }
  };

  const handleDomainChange = (index, value) => {
    const updatedDomains = [...domains];
    updatedDomains[index] = value;
    setDomains(updatedDomains);
  };

  const handleAddDomain = () => {
    if (domains.length < (subscriptionLevel === 'business' ? 3 : subscriptionLevel === 'enterprise' ? 5 : 1)) {
      setDomains([...domains, '']);
    } else {
      alert(
        `Your subscription level allows a maximum of ${
          subscriptionLevel === 'business' ? 3 : subscriptionLevel === 'enterprise' ? 5 : 1
        } domains.`
      );
    }
  };

  const handleRemoveDomain = (index) => {
    const updatedDomains = domains.filter((_, i) => i !== index);
    setDomains(updatedDomains);
  };

  const handleViewBilling = () => {
    navigate('/profile');
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
      alert('Failed to log out');
    }
  };

  if (loading) {
    return (
      <FullScreenContainer>
        <CircularProgress />
      </FullScreenContainer>
    );
  }

  return (
    <FullScreenContainer>
      <SettingsPaper>
        <Header>
          <Typography variant="h4" gutterBottom>
            Settings
          </Typography>
        </Header>
        <Grid container spacing={3}>
          {/* User Information */}
          <Grid item xs={12} sm={6}>
            <StyledTextField
              label="Name"
              fullWidth
              value={userData?.fullName || ''}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              label="Email"
              fullWidth
              value={userData?.email || ''}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              label="Phone Number"
              fullWidth
              value={userData?.phoneNumber || ''}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              label="Company Domain"
              fullWidth
              value={userData?.companyDomain || ''}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              margin="dense"
            />
          </Grid>

          {/* Report Frequency */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel>Report Frequency</InputLabel>
              <Select
                value={reportFrequency}
                onChange={(e) => setReportFrequency(e.target.value)}
                label="Report Frequency"
              >
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
              </Select>
              <FormHelperText>Current selection: {reportFrequency}</FormHelperText>
            </FormControl>
          </Grid>


          {/* Subscription Level */}
          <Grid item xs={12}>
            <Typography variant="h6">Subscription Level</Typography>
            <Typography>
              {subscriptionLevel.charAt(0).toUpperCase() + subscriptionLevel.slice(1)}
            </Typography>
          </Grid>

          {/* Action Buttons */}
          <Grid item xs={12}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleSaveSettings}
              fullWidth
            >
              Save Settings
            </StyledButton>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleViewBilling}
              fullWidth
              sx={{ mt: 2 }}
            >
              View Billing
            </Button>
          </Grid>
        </Grid>

        {/* Logout Button Positioned at the Bottom */}
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <LogoutButton variant="outlined" onClick={handleLogout}>
            Logout
          </LogoutButton>
        </Box>
      </SettingsPaper>
    </FullScreenContainer>
  );
};

export default SettingsPage;
