import React from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardContent, Typography, Box } from '@mui/material';

const StyledCard = styled(Card)(({ theme }) => ({
    minWidth: 275,
    margin: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    '&:hover': {
        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
        transform: 'scale(1.05)',
    },
    '& .MuiCardContent-root': {
        textAlign: 'center',
    },
    '& .MuiTypography-h5': {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    '& .MuiTypography-gutterBottom': {
        color: theme.palette.text.secondary,
    },
}));
const GlassEffect = styled('div')(({ theme }) => ({
    backdropFilter: 'blur(4px)',
    backgroundColor: 'rgba(30, 30, 30,1)',
    // ... other styles
}));
const OverviewCard = ({ title, value, icon }) => {
        return ( <
            StyledCard >
            <
            GlassEffect >
            <
            CardContent >
            <
            Box display = "flex"
            alignItems = "center"
            justifyContent = "center" > {
                icon && < Box marginRight = { 1 } > { icon } < /Box>} <
                Typography color = "textSecondary"
                gutterBottom > { title } <
                /Typography> < /
                Box > <
                Typography variant = "h5"
                component = "h2" > { value } <
                /Typography>< /
                CardContent >
                <
                /GlassEffect> < /
                StyledCard >
            );
        };

        export default OverviewCard;