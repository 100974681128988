// src/components/ThreatFeed.js
import React, { useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  styled,
  CircularProgress,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const ChatContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#000', // Black background to match the window
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  boxShadow: 'none',
}));

const MessagesContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  marginBottom: theme.spacing(2),
  paddingRight: theme.spacing(1),
}));

const MessageBubble = styled(Paper)(({ theme, isUser }) => ({
  backgroundColor: isUser ? '#2f4f4f' : '#36454F', 
  color: '#fff',
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(2),
  maxWidth: '75%',
  alignSelf: isUser ? 'flex-end' : 'flex-start',
  boxShadow: 'none',
  marginBottom: theme.spacing(1),
}));

const InputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  paddingTop: theme.spacing(1),
  borderTop: '1px solid #555',
  borderRadius: '12px',
  backgroundColor: '#1a1a1a',
}));

const Timestamp = styled(Typography)(({ theme }) => ({
  color: '#bbb',
  fontSize: '0.75rem',
  textAlign: 'right',
  marginTop: theme.spacing(0.5),
}));

export default function ThreatFeed({ 
  userData, 
  messages, 
  inputValue, 
  onInputChange, 
  onSendMessage, 
  loading 
}) {
  const messagesEndRef = useRef(null);

  // Scroll to bottom whenever messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSendMessage();
    }
  };

  return (
    <ChatContainer>
      <Divider sx={{ backgroundColor: '#555', marginBottom: 2 }} />
      <MessagesContainer>
        <List disablePadding>
          {messages.map((message, index) => (
            <ListItem
              key={index}
              disableGutters
              sx={{ display: 'flex', flexDirection: 'column', alignItems: message.role === 'user' ? 'flex-end' : 'flex-start' }}
            >
              <MessageBubble elevation={0} isUser={message.role === 'user'}>
                <ListItemText primary={message.content} />
              </MessageBubble>
              <Timestamp>
                {new Date().toLocaleString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </Timestamp>
            </ListItem>
          ))}
          <div ref={messagesEndRef} />
        </List>
      </MessagesContainer>

      {loading && (
        <Box display="flex" alignItems="center" mb={1}>
          <CircularProgress size={20} />
          <Typography variant="body1" color="white" sx={{ ml: 1 }}>
            AI is typing...
          </Typography>
        </Box>
      )}

      <InputContainer>
        <TextField
          variant="outlined"
          placeholder="Type your message..."
          fullWidth
          multiline
          maxRows={4}
          value={inputValue}
          onChange={(e) => onInputChange(e.target.value)}
          onKeyPress={handleKeyPress}
          InputProps={{
            style: { backgroundColor: '#1a1a1a', color: '#fff' },
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#555',
                borderRadius: '12px',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#777',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#4a90e2',
              },
              color: '#fff',
            },
          }}
        />
        <IconButton
          color="primary"
          onClick={onSendMessage}
          sx={{
            backgroundColor: '#000',
            width: '40px',
            height: '40px',
            '&:hover': {
              backgroundColor: '#333',
            },
          }}
        >
          <SendIcon sx={{ color: '#fff' }} />
        </IconButton>
      </InputContainer>
    </ChatContainer>
  );
}
