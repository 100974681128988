// src/components/BrandProtection.js

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Drawer,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled TableContainer with fixed height and custom scrollbar
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: 400, // Fixed height
  backgroundColor: '#1e1e1e', // Dark background
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#1e1e1e',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#555',
    borderRadius: '4px',
    border: '2px solid #1e1e1e',
  },
  // For Firefox
  scrollbarWidth: 'thin',
  scrollbarColor: '#555 #1e1e1e',
}));

const BrandProtection = ({ detections }) => {
  const [selectedThreat, setSelectedThreat] = useState(null);

  // Dummy data when no real detections are available
  const dummyData = [
    {
      id: 'Dummy-001',
      domain: 'demo-fraudulent.com',
      status: 'Demo Detected',
      detectionDate: '2024-01-01',
      detectionType: 'Brand Protection', // Ensure this matches the filter
      details: 'This is demo data. It illustrates how real brand protection threats will appear in the system.',
    },
    {
      id: 'Dummy-002',
      domain: 'demo-phishing.net',
      status: 'Demo Under Investigation',
      detectionDate: '2024-01-02',
      detectionType: 'Brand Protection', // Ensure this matches the filter
      details: 'This is demo data. It shows the format and information provided for actual phishing attempts targeting your brand.',
    },
    {
      id: 'Dummy-003',
      domain: 'demo-malicious.org',
      status: 'Demo Blocked',
      detectionDate: '2024-01-03',
      detectionType: 'Brand Protection', // Ensure this matches the filter
      details: 'This is demo data. It demonstrates how blocked malicious content related to brand protection is handled.',
    },
  ];

  // Filter detections to only include those related to Brand Protection
  const brandProtectionDetections =
    detections && detections.length > 0
      ? detections.filter(
          (threat) =>
            threat.detectionType &&
            threat.detectionType.toLowerCase() === 'brand protection'
        )
      : dummyData;

  // If no real detections are present, use dummyData
  const displayData =
    detections && detections.length > 0
      ? brandProtectionDetections.length > 0
        ? brandProtectionDetections
        : dummyData
      : dummyData;

  // Handle row click to open the side panel
  const handleRowClick = (threat) => {
    setSelectedThreat(threat);
  };

  // Handle closing the side panel
  const handleClose = () => {
    setSelectedThreat(null);
  };

  // Function to format date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const dateObj = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', options).format(dateObj);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <StyledTableContainer
        component={Paper}
        sx={{
          boxShadow: 5,
          backgroundColor: '#1e1e1e', // Dark background for better contrast
          width: '100%', // Full width table
        }}
      >
        <Table stickyHeader aria-label="Brand Protection Table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Domain</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Detection Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.length > 0 ? (
              displayData.map((threat) => (
                <TableRow
                  key={threat.id}
                  onClick={() => handleRowClick(threat)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#333333' }, // Consistent dark hover
                  }}
                >
                  <TableCell sx={{ color: 'white' }}>{threat.domain}</TableCell>
                  <TableCell sx={{ color: 'white' }}>{threat.status}</TableCell>
                  <TableCell sx={{ color: 'white' }}>{formatDate(threat.detectionDate)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} sx={{ color: 'white', textAlign: 'center' }}>
                  No Brand Protection threats detected at this moment.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>

      {/* Side Drawer for Threat Details */}
      <Drawer anchor="right" open={Boolean(selectedThreat)} onClose={handleClose}>
        <Box
          sx={{
            width: 350,
            padding: 3,
            backgroundColor: '#2c2c2c', // Dark background for the drawer
            height: '100%',
            overflowY: 'auto',
          }}
        >
          {selectedThreat && (
            <>
              <Typography variant="h6" sx={{ color: 'white' }}>
                {selectedThreat.domain}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'white' }}>
                Status: {selectedThreat.status}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: 'white' }}>
                Detection Type: {selectedThreat.detectionType}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: 'white' }}>
                Detection Date: {formatDate(selectedThreat.detectionDate)}
              </Typography>
              <Typography variant="body2" sx={{ marginTop: 2, color: 'white' }}>
                {selectedThreat.details}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: 3 }}
                onClick={handleClose}
                fullWidth
              >
                Close
              </Button>
            </>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default BrandProtection;
