import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Avatar,
  Link,
  Modal,
  Box,
  Button,
  CircularProgress,
} from '@mui/material';
import { FaShieldAlt } from 'react-icons/fa';
import { styled } from '@mui/material/styles';
import axios from 'axios';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: theme.shadows[4],
    cursor: 'pointer',
  },
  height: '250px', // Fixed height for all cards
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderLeft: `5px solid ${theme.palette.primary.main}`, // Color accent on the side of each card
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(4, 0, 2),
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.5rem',
}));

const ModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  backgroundColor: 'rgba(255, 255, 255, 0.15)', // Glass effect background
  backdropFilter: 'blur(10px)', // Blur effect
  boxShadow: 24,
  padding: theme.spacing(4),
  borderRadius: '15px',
}));

const RoundedButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '5px 15px',
  backgroundColor: theme.palette.primary.light,
  color: '#fff',
  fontWeight: 'bold',
  fontSize: '0.8rem',
  minWidth: '100px', // Smaller button width
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const HeatMap = () => {
  const [incidents, setIncidents] = useState([]);
  const [loadingIncidents, setLoadingIncidents] = useState(true);
  const [errorIncidents, setErrorIncidents] = useState(null);
  const [expandedIncident, setExpandedIncident] = useState(null); // Modal state
  const [visibleIncidents, setVisibleIncidents] = useState(6); // Initially show 6 incidents
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const fetchIncidents = async () => {
      try {
        const response = await axios.get('https://w2lw8tk4-3005.uks1.devtunnels.ms/api/incidents');
        setIncidents(response.data.incidents);
      } catch (error) {
        console.error('Error fetching incidents:', error.message);
        setErrorIncidents('Failed to load incidents.');
      } finally {
        setLoadingIncidents(false);
      }
    };

    fetchIncidents();
  }, []);

  const handleOpenModal = (incident) => {
    setExpandedIncident(incident);
  };

  const handleCloseModal = () => {
    setExpandedIncident(null);
  };

  const getIncidentTitle = (incident) => {
    return incident.description.slice(0, 40) + '...';
  };

  const showMoreIncidents = () => {
    setVisibleIncidents(incidents.length); // Show all incidents
    setIsExpanded(true);
  };

  const showLessIncidents = () => {
    setVisibleIncidents(6); // Show only 6 incidents
    setIsExpanded(false);
  };

  return (
    <Container maxWidth="lg" style={{ padding: '40px 0' }}>
      <SectionHeader variant="h5">
        <FaShieldAlt style={{ marginRight: '8px' }} />
        Latest Cybersecurity Incidents
      </SectionHeader>
      {loadingIncidents ? (
        <CircularProgress color="secondary" />
      ) : errorIncidents ? (
        <Typography variant="body1" color="error">
          {errorIncidents}
        </Typography>
      ) : (
        <>
          <Grid container spacing={4}>
            {incidents.length === 0 ? (
              <Typography variant="body1">No incidents available at the moment.</Typography>
            ) : (
              incidents.slice(0, visibleIncidents).map((incident) => (
                <Grid item xs={12} sm={6} md={4} key={incident.id}>
                  <StyledCard onClick={() => handleOpenModal(incident)}>
                    <CardHeader
                      avatar={
                        <Avatar sx={{ backgroundColor: '#b0b0b0' }}>
                          S
                        </Avatar>
                      }
                      title={getIncidentTitle(incident)}
                      subheader={new Date(incident.date).toLocaleDateString()}
                    />
                    <CardContent>
                      <Typography variant="body2" color="textSecondary">
                        {incident.description.slice(0, 100)}... {/* Shortened description */}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))
            )}
          </Grid>

          {visibleIncidents < incidents.length && (
            <Box display="flex" justifyContent="center" marginTop={4} marginBottom={2}>
              <RoundedButton variant="contained" onClick={showMoreIncidents}>
                Show More
              </RoundedButton>
            </Box>
          )}

          {isExpanded && (
            <Box display="flex" justifyContent="center" marginTop={4} marginBottom={2}>
              <RoundedButton variant="contained" onClick={showLessIncidents}>
                Show Less
              </RoundedButton>
            </Box>
          )}
        </>
      )}

      {/* Modal for viewing full incident details */}
      <Modal open={!!expandedIncident} onClose={handleCloseModal}>
        <ModalBox>
          {expandedIncident && (
            <>
              <Typography variant="h6" gutterBottom>
                {getIncidentTitle(expandedIncident)}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {expandedIncident.description}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Date:</strong> {new Date(expandedIncident.date).toLocaleDateString()}
              </Typography>
            </>
          )}
        </ModalBox>
      </Modal>
    </Container>
  );
};

export default HeatMap;
