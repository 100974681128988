// src/pages/Dashboard.js

import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  useTheme,
  Tooltip,
  Drawer,
  Fab,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ChatIcon from '@mui/icons-material/Chat';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import RecentCVE from './Components/RecentCVE';
import HeatMap from './Components/HeatMap';
import FindingsTable from './Components/FindingsTable';
import OverviewCard from './Components/OverviewCard';
import ThreatFeed from './Components/ThreatFeed';
import BusinessIcon from '@mui/icons-material/Business';
import SecurityIcon from '@mui/icons-material/Security';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BrandProtection from './Components/BP';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const MainContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  backgroundColor: '#000', // Black background
}));

// Utility function to standardize detection findings
const mapFindingToStandardFormat = (finding) => {
  let date, source, type, detail, id;
  if (finding.dateOfDetection && finding.port) {
    id = finding.id;
    date = finding.dateOfDetection;
    source = 'Network Scan';
    type = `Open Port - ${finding.port}`;
    detail = finding.detail;
  } else if (finding.date && finding.partitionKey) {
    id = finding.id;
    date = finding.date;
    source = finding.channel_name || 'Unknown Source';
    type = 'Compromised credentials';
    detail = finding.content || 'No details available';
  } else {
    id = finding.id || 'Unknown';
    date = finding.detectionDate || 'Unknown';
    source = 'Unknown Source';
    type = 'Unknown Type';
    detail = 'No details available';
  }
  return { id, date, source, type, detail };
};

export default function Dashboard({ data, loading }) {
  const theme = useTheme();
  const [threatFeedOpen, setThreatFeedOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  // Controlled state for the ThreatFeed
  const [messages, setMessages] = useState([
    { role: 'assistant', content: 'Hello! How can I help you today?' }
  ]);
  const [inputValue, setInputValue] = useState('');
  const [loadingAI, setLoadingAI] = useState(false);

  const toggleThreatFeed = (open) => () => {
    setThreatFeedOpen(open);
  };

  const handleExpandClick = () => {
    // Close the drawer, open the dialog
    setThreatFeedOpen(false);
    setExpanded(true);
  };

  const handleExpandClose = () => {
    setExpanded(false);
  };

  const handleInputChange = (val) => {
    setInputValue(val);
  };

  const handleSendMessage = () => {
    if (!inputValue.trim()) return;
    const userMessage = { role: 'user', content: inputValue.trim() };
    setMessages((prev) => [...prev, userMessage]);

    // Simulate an AI response
    setLoadingAI(true);
    setTimeout(() => {
      const aiResponse = { role: 'assistant', content: 'This is an AI response.' };
      setMessages((prev) => [...prev, aiResponse]);
      setLoadingAI(false);
    }, 1000);

    setInputValue('');
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  // No data available state
  if (!data) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography variant="h5" color="white">
          No data available at the moment. Please check back later.
        </Typography>
      </Box>
    );
  }

  const {
    companyDomain = 'Domain not set',
    detections = [],
    searches = [],
    vulnerabilities = [],
    similarDomains = [],
    similarDomainsCount = similarDomains.length, // Fallback to length if count isn't provided
    healthStatus = 'Unknown',
    lastLogin = 'Never',
    isFirstLogin = true,
  } = data;

  // Standardize detection findings
  const standardizedFindings = detections.map(mapFindingToStandardFormat);

  // Calculate report counts
  const reportCount = searches;
  const detectionCount = detections.length;

  return (
    <MainContent>
      <Grid container spacing={3}>
        {/* Top Section: Overview Cards */}
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={12} md={3}>
            <OverviewCard
              title="Detected Breaches Count"
              value={detectionCount.toLocaleString()}
              icon={<BusinessIcon />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <OverviewCard 
              title="Number of Searches"
              value={reportCount.toLocaleString()} // Format with commas
              icon={<SecurityIcon />} 
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <OverviewCard
              title="Monitoring Status"
              value="Active"
              icon={<AccessTimeIcon />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <OverviewCard
              title="Potential Threats Monitored"
              value={similarDomainsCount.toLocaleString()}
              icon={<AccessTimeIcon />}
            />
          </Grid>
        </Grid>

        {/* Section: Recent Events & Detections */}
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={12}>
            <Box>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="h6" gutterBottom color="white">
                  Recent Events & Detections
                </Typography>
                <Tooltip title="Latest events and detections across the platform." arrow>
                  <InfoIcon sx={{ color: 'grey', marginLeft: 1, fontSize: '1.2rem' }} />
                </Tooltip>
              </Box>
              <RecentCVE detections={detections} />
            </Box>
          </Grid>
        </Grid>

        {/* Third Section: Telegram Monitoring */}
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={12}>
            <Box>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="h6" gutterBottom color="white">
                  Telegram Monitoring
                </Typography>
                <Tooltip title="Monitoring Telegram for VIP protection and other security events." arrow>
                  <InfoIcon sx={{ color: 'grey', marginLeft: 1, fontSize: '1.2rem' }} />
                </Tooltip>
              </Box>
              <FindingsTable findings={standardizedFindings} />
            </Box>
          </Grid>
        </Grid>

        {/* Fifth Section: Incident HeatMap */}
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={12}>
            <Box>
              <HeatMap />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* Floating Action Button to Open AI Assistant Chatbot */}
      <Fab
        color="primary"
        aria-label="chat"
        onClick={toggleThreatFeed(true)}
        sx={{
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(4),
          backgroundColor: '#000',
          '&:hover': {
            backgroundColor: '#333',
          },
        }}
      >
        <ChatIcon sx={{ color: '#fff' }} />
      </Fab>

      {/* Drawer with ThreatFeed (only if not expanded) */}
      {!expanded && (
        <Drawer
          anchor="right"
          open={threatFeedOpen}
          onClose={toggleThreatFeed(false)}
          PaperProps={{
            sx: {
              width: { xs: '100%', sm: '600px' },
              backgroundColor: '#1a1a1a',
              color: '#fff',
              borderTopLeftRadius: '20px',
              borderBottomLeftRadius: '20px',
              boxShadow: 'none',
            },
          }}
        >
          <Box
            role="presentation"
            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={2}
              sx={{
                borderBottom: '1px solid #333',
                borderTopLeftRadius: '20px',
              }}
            >
              <Typography variant="h6">AI Assistant</Typography>
              <Box display="flex" gap={1} alignItems="center">
                <Tooltip title="Expand to larger view" arrow>
                  <IconButton onClick={handleExpandClick} sx={{ color: '#fff' }}>
                    <FullscreenIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Close" arrow>
                  <IconButton onClick={toggleThreatFeed(false)} sx={{ color: 'grey' }}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box flexGrow={1} p={2} overflow="auto">
              <ThreatFeed
                userData={data}
                messages={messages}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onSendMessage={handleSendMessage}
                loading={loadingAI}
              />
            </Box>
          </Box>
        </Drawer>
      )}

      {/* Dialog for Expanded View (renders the same ThreatFeed) */}
      {expanded && (
        <Dialog
          open={expanded}
          onClose={handleExpandClose}
          fullWidth
          maxWidth="lg"
          PaperProps={{
            sx: {
              backgroundColor: '#1a1a1a',
              color: '#fff',
              borderRadius: '20px',
              overflow: 'hidden',
            },
          }}
        >
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">AI Assistant (Expanded View)</Typography>
            <IconButton
              onClick={handleExpandClose}
              size="small"
              disableRipple
              disableFocusRipple
              sx={{
                p: '4px',
                color: 'grey',
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: '#fff',
                },
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ height: '70vh' }}>
            <ThreatFeed
              userData={data}
              messages={messages}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              onSendMessage={handleSendMessage}
              loading={loadingAI}
            />
          </DialogContent>
        </Dialog>
      )}
    </MainContent>
  );
}
