// src/components/FindingsTable.js

import React, { useState } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Drawer, 
  Typography, 
  Box, 
  Button 
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled TableContainer with fixed height and custom scrollbar
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: 400, // Fixed height
  backgroundColor: '#1e1e1e', // Dark background
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#1e1e1e',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#555',
    borderRadius: '4px',
    border: '2px solid #1e1e1e',
  },
  // For Firefox
  scrollbarWidth: 'thin',
  scrollbarColor: '#555 #1e1e1e',
}));

const FindingsTable = ({ findings }) => {
  // State to manage the selected finding and drawer visibility
  const [selectedFinding, setSelectedFinding] = useState(null);

  // Dummy data specific to Telegram Monitoring and VIP Protection
  const dummyData = [
    {
      date: '2024-01-11',
      source: 'Telegram Monitoring',
      type: 'VIP Protection Alert',
      detail: 'Detected messages mentioning a high-profile individual in a monitored Telegram group.',
      extraInfo: 'Further investigation suggested that the individual was mentioned in hostile discussions.',
    },
    {
      date: '2024-01-12',
      source: 'Telegram Monitoring',
      type: 'Suspicious Activity',
      detail: 'Potential phishing attempt targeting VIP individuals identified in a monitored Telegram chat.',
      extraInfo: 'Multiple phishing messages detected in the chat. Users are advised to be cautious.',
    },
    {
      date: '2024-01-15',
      source: 'Telegram Monitoring',
      type: 'Threat Intelligence',
      detail: 'Observed hostile discussions related to VIP security in a monitored Telegram channel.',
      extraInfo: 'Security measures are recommended as a precaution.',
    },
  ];

  // Use dummy data if findings array is empty
  const displayData = findings.length > 0 ? findings : dummyData;

  // Handle row click to open the side drawer
  const handleRowClick = (finding) => {
    setSelectedFinding(finding);
  };

  // Handle closing the side drawer
  const handleClose = () => {
    setSelectedFinding(null);
  };

  // Function to format date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const dateObj = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', options).format(dateObj);
  };

  return (
    <Box>
      <StyledTableContainer component={Paper}>
        <Table stickyHeader aria-label="findings table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Date</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Source</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Type</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Detail</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((finding, index) => (
              <TableRow
                key={index}
                onClick={() => handleRowClick(finding)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: '#333333' }, // Dark hover background to match theme
                }}
              >
                <TableCell sx={{ color: 'white' }}>{formatDate(finding.date)}</TableCell>
                <TableCell sx={{ color: 'white' }}>{finding.source}</TableCell>
                <TableCell sx={{ color: 'white' }}>{finding.type}</TableCell>
                <TableCell sx={{ color: 'white' }}>{finding.detail}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      {/* Side Drawer for Extra Data */}
      <Drawer anchor="right" open={Boolean(selectedFinding)} onClose={handleClose}>
        <Box sx={{ width: 350, padding: 3, backgroundColor: '#2c2c2c' }}>
          {selectedFinding && (
            <>
              <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
                {selectedFinding.type}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'white' }}>
                <strong>Date:</strong> {formatDate(selectedFinding.date)}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'white' }}>
                <strong>Source:</strong> {selectedFinding.source}
              </Typography>
              <Typography variant="body1" sx={{ color: 'white', marginTop: 2 }}>
                {selectedFinding.detail}
              </Typography>
              <Typography variant="body2" sx={{ color: 'white', marginTop: 2 }}>
                <strong>Extra Info:</strong> {selectedFinding.extraInfo || 'No extra information available.'}
              </Typography>
              <Button 
                variant="contained" 
                color="primary" 
                sx={{ marginTop: 3 }} 
                onClick={handleClose}
                fullWidth
              >
                Close
              </Button>
            </>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default FindingsTable;
