// src/login.js
import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Button, TextField, Paper, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Login.css";

const Login = ({ setIsAuthenticated }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState("");
    const navigate = useNavigate();
    const auth = getAuth();

    const handleLogin = async(event) => {
        event.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            setIsAuthenticated(true);
            navigate("/");
        } catch (error) {
            setLoginError("Failed to login. Please check your credentials.");
            console.error("Login error:", error);
        }
    };

    return ( <
        Container component = "main"
        maxWidth = "xs" >
        <
        Paper elevation = { 6 }
        style = {
            { marginTop: "8vh", padding: "20px" } } >
        <
        Typography component = "h1"
        variant = "h5" >
        Sign in
        <
        /Typography> <
        form onSubmit = { handleLogin } >
        <
        TextField variant = "outlined"
        margin = "normal"
        required fullWidth id = "email"
        label = "Email Address"
        name = "email"
        autoComplete = "email"
        autoFocus value = { email }
        onChange = {
            (e) => setEmail(e.target.value) }
        /> <
        TextField variant = "outlined"
        margin = "normal"
        required fullWidth name = "password"
        label = "Password"
        type = "password"
        id = "password"
        autoComplete = "current-password"
        value = { password }
        onChange = {
            (e) => setPassword(e.target.value) }
        /> {
            loginError && ( <
                Typography color = "error"
                variant = "body2" > { loginError } <
                /Typography>
            )
        } <
        Button type = "submit"
        fullWidth variant = "contained"
        color = "primary"
        style = {
            { margin: "24px 0px 16px" } } >
        Sign In <
        /Button> <
        /form> <
        /Paper> <
        /Container>
    );
};

export default Login;